export const categories = {
  ALL: {
    id: 'ALL',
    label: '전체',
    icon: '🏠',
    description: '모든 글 보기'
  },
  ARTICLE: {
    id: 'ARTICLE',
    label: '아티클',
    icon: '☕',
    description: '나의 생각'
  },
  LIFE: {
    id: 'LIFE',
    label: '일상',
    icon: '☕',
    description: '일상 이야기'
  },
  WEEKLY: {
    id: 'WEEKLY',
    label: '주간일기',
    icon: '☕',
    description: '위클리 일기'
  },
  BOOKREVIEW: {
    id: 'BOOKREVIEW',
    label: '독후감',
    icon: '☕',
    description: '책 이야기'
  },
  RETROSPECT: {
    id: 'RETROSPECT',
    label: '회고',
    icon: '📝',
    description: '지난 일을 돌아보는 글'
  },
  // ... 필요한 카테고리 추가
}

export const getCategoryLabel = (categoryId) => {
  return categories[categoryId]?.label || categoryId
}