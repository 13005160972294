import * as React from "react"
import { Link, graphql } from "gatsby"
import { categories, getCategoryLabel } from "../constants/categories"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  
  // 현재 선택된 카테고리 상태 관리
  const [selectedCategory, setSelectedCategory] = React.useState(categories.ALL.id)
  
  // 선택된 카테고리에 따라 포스트 필터링
  const filteredPosts = selectedCategory === categories.ALL.id
    ? posts 
    : posts.filter(post => post.frontmatter.category === selectedCategory)

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Bio />
        <p>No blog posts found...</p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      {/* 카테고리 탭 */}
      <div className="category-tabs">
        {Object.values(categories).map(category => (
          <button
            key={category.id}
            onClick={() => setSelectedCategory(category.id)}
            className={`category-tab ${selectedCategory === category.id ? 'active' : 'inactive'}`}
          >
            {category.label}
          </button>
        ))}
      </div>

      {/* 포스트 목록 */}
      <ol style={{ listStyle: `none` }}>
        {filteredPosts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li key={post.fields.slug} className="article-title post-list-item">
              <header>
                <div className="post-list-item-info">
                  <span className="post-date">
                    {post.frontmatter.date}
                  </span>
                  {post.frontmatter.category && (
                    <span className="post-category">
                      {getCategoryLabel(post.frontmatter.category)}
                    </span>
                  )}
                </div>
                <h3 className="post-title">
                  <Link to={post.fields.slug}>
                    {title}
                  </Link>
                </h3>
                {post.frontmatter.subtitle && (
                  <h4 className="post-subtitle">
                    {post.frontmatter.subtitle}
                  </h4>
                )}
              </header>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="나나산 nanasan 블로그" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY.MM.DD")
          title
          subtitle
          category
        }
      }
    }
  }
`
