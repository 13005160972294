import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <h2>〰 나는 나로 산다.</h2>
      <i>12년차 프로덕트 디자이너, 나나산입니다.</i>
      <p>
        콘텐츠, 이커머스, HR 도메인에서 B2C 모바일 제품, SaaS 등 다양한 제품을 만들어 왔습니다.
      </p>
      <p>
        주로 프로덕트 디자이너로 일했지만, 디자인 리드로 채용부터 매니징까지 HR 경험, 디자인 시스템을 만드는 플랫폼 디자인 업무 경험도 있습니다.
      </p>
      <p>
        사이드 프로젝트 매니아로 디자이너를 위한 코딩 교육부터 갓생을 인증하는 타임스탬프 앱 Show Your Time까지 다양한 프로젝트를 진행했습니다.
      </p>
      <p>
        다른 이들의 성장에도 관심이 많아서 원티드에서 프로덕트 디자이너의 이력서•포트폴리오 멘토링과 주니어 프로덕트 디자이너를 위한 강의를 진행하기도 했습니다.
      </p>
      <p>
        현재는 <a href="https://fi-workers.com/" target="_blank">fi-workers</a> 팀을 창업하고, <a href="https://showyourti.me/" target="_blank">Show Your Time</a> 앱을 만들고 있습니다.
      </p>
      <p><a href="https://www.youtube.com/@nanasan-studio" target="_blank">📺 nanasan 유튜브 채널</a><br/>
      <a href="https://x.com/nanasan_writes" target="_blank">🏠 nanasan 트위터</a></p>
    </Layout>
  )
}

export const Head = () => <Seo title="About" />

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
